import React, { useState, useRef, useContext, useEffect } from "react"
import classNames from "classnames";
import Markdown from 'markdown-to-jsx';

import iconPlus from "../images/plus.svg";
import iconClose from "../images/close-circle.svg";
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import Link from "./link";
import { CustomHr } from "./CustomHr";

const CookieConsent = ({ heading, body, label }) => {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const [cookieConsented, setCookieConsented] = useState(true);

  const hideCookies = () => {
    dispatch({ type: "CLOSE_COOKIE_CONSENT" });
  }

  const acceptCookies = () => {
    window.localStorage.setItem("cookie_consented", true);
    hideCookies();
  }

  useEffect(() => {
    setCookieConsented(typeof window === 'undefined' || window.localStorage.getItem("cookie_consented"));
  })

  const showCookie = !cookieConsented && state.cookieConsent.isOpen

  return (
    <>
      {showCookie && (
        <div className={[
          "cookie-consent", 
          cookieConsented || !state.cookieConsent.isOpen ? "cookie-consent--hidden" : "",
        ].join(" ")}>
          <div className="cookie-consent__inner">
            <button className="cookie-consent__close" onClick={hideCookies}><img src={iconClose} loading="lazy" /></button>
            <h2 className="cookie-consent__heading">{heading}</h2>
            <div className="cookie-consent__body rich-text">
              <Markdown options={{
                overrides: {a: {
                  component: Link
                },                
              }
              }}>
                {body || ''}
              </Markdown> 
            </div>
            <button className="cookie-consent__button button button--pink button--plain" onClick={acceptCookies}>
              <span className="button__text">{label}</span>
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default CookieConsent
