/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { GlobalStateContext } from "../context/GlobalContextProvider"
import classNames from "classnames"

function SEO({ description, lang, title, template, keywords, darkTheme, darkMode, websiteBackgroundColor, websiteFontColor}) {
  const state = useContext(GlobalStateContext)
  const isClient = typeof window !== 'undefined';
  const defaultKeywords = 'softwareentwicklung, webentwicklung, agentur, berlin';
  const metaKeywords = keywords ? keywords : defaultKeywords;
  // const currentTime = new Date().getHours();
  // let isNightTime = currentTime >= 18 || currentTime < 8;

  // Check for the transition between days
  // if (currentTime >= 0 && currentTime < 8) {
  //   isNightTime = true; // It's considered night time if the current time is between midnight and 8 am
  // }

  const { strapi } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        strapi {
          theme {
            switchToDarkMode
          }
        }
      }
    `
  )
  
  const switchToDarkMode = strapi?.theme?.switchToDarkMode;

  return (
    <Helmet
      htmlAttributes={{ 
        lang,
        class: classNames({
          noscroll: state.siteNav.isOpen,
        }),
      }}
      title={title}
      //  seo title is now  being populated from strapi backend 

      bodyAttributes={{
        class: classNames(`template-${template} push`, {
          open: state.siteNav.isOpen,
          'dark': darkTheme || switchToDarkMode || darkMode,
          // 'dark-transition': !isNightTime && !darkTheme && switchToDarkMode
        }),
        style: isClient ? `display: none; background-color: ${websiteBackgroundColor}; color: ${websiteFontColor}` : {display: 'none', backgroundColor: websiteBackgroundColor, color: websiteFontColor}
      }}
    >
      <meta charset="UTF-8" />

      <base href="/" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="Description" content={description} />
      <meta name="keywords" content={metaKeywords} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://endlzr.com" />
      <meta property="og:image" content="https://i.imgur.com/lxRBpeb.jpg" />
      <meta property="og:image:alt" content="endzlr logo" />
      <meta property="og:description" content={description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="@VentzkeMedia" />

      <meta name="theme-color" content="#2c638f" />
      <meta name="msapplication-navbutton-color" content="#2c638f" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  template: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  template: PropTypes.string,
}

export default SEO
