/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext, useEffect, useRef, useState } from "react";
import SVG from 'react-inlinesvg';
import PropTypes from "prop-types"; 
import { useStaticQuery, graphql } from "gatsby"
import { IntlProvider, addLocaleData } from "react-intl";
import messages_de from "../../translations/de.json";
import messages_en from "../../translations/en.json";
import AnchorNavigation from "../anchorNav";

import SiteHeader from "../site-header"
import SiteFooter from "../site-footer"
import SiteFooterShort from "../site-footer-short.js";
import { isAnchorNavPage, isMobile } from "../../utils";
import classNames from "classnames";
import { GlobalDispatchContext, GlobalStateContext } from "../../context/GlobalContextProvider";
import moon from "../../images/moon.svg";
import sun from "../../images/sun.svg";
import SwitchBtn from "../switchBtn.js";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const messages = {
  'de': messages_de,
  'en': messages_en
};

const Layout = ({header, footer, children, language, showBlackNav, serviceEmail, showWhiteIcon, redirectTo, anchorLinks, showX, isImage, isHomepage, isContactPage, servicesfooterColor, isServicesLandingPage}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      strapi {
        general {
          footerLightModeBgColor
          footerDarkModeBgColor
          clientLogosColorDarkMode
          clientLogosColorLightMode
        }
      }

      site {
        siteMetadata {
          title
        }

      }
    }
  `)

  language = language || "en";
  const state = useContext(GlobalStateContext);
  const svgUrl = state.svgUrl;
  const [fadeOut, setFadeOut] = useState(false); 
  const darkMode = state.darkModePreference === 'moon'; 
  const breakpoints = useBreakpoint();
  const { clientLogosColorLightMode, clientLogosColorDarkMode } = data?.strapi?.general; 
  const footerLightModeBgColor = data?.strapi?.general?.footerLightModeBgColor; 
  const footerDarkModeBgColor = data?.strapi?.general?.footerDarkModeBgColor; 
  const footerBgColor = darkMode ? footerDarkModeBgColor : footerLightModeBgColor
  const dispatch = useContext(GlobalDispatchContext);
  const darkModePreference = state.darkModePreference; 
  const isScrolled = state.closeSwitcher;
  const hasSwitcherAnimation = state.hasSwitcherAnimation;
  const [hideBtn, setHideBtn] = useState(false);
  const switchBtnRef = useRef(null);
  const logosBgColor = darkMode ? clientLogosColorDarkMode : clientLogosColorLightMode; 


  useEffect(() => {
    document.body.scrollTo(0,0)
  }, [data])

  useEffect(() => {
   const isSun = window.localStorage.getItem("darkModePreference"); 
   if (isSun === 'sun') {
     dispatch({ type: 'HIDE_HOMEPAGE_DARK_MODE' , payload: "sun"});
   } else if (isSun === 'moon') {
     dispatch({ type: 'SHOW_HOMEPAGE_DARK_MODE' , payload: "moon"});
   }
    const location = typeof window !== "undefined" && window.location.pathname;
    if (!isHomepage) {
      setTimeout(() => {
        dispatch({ type: "TURN_SWITCHER_ANIMATION"});
      }, 1000)
      dispatch({type: 'SET_PREVIOUS_URL', payload: location}); 
    }
    return () => {
      if(isServicesLandingPage) {
        dispatch({type: 'SET_LAST_VISITED_PAGE', payload: location}); 
      } else {
        dispatch({type: 'SET_LAST_VISITED_PAGE', payload: ''}); 
      }
    }
  }, []);



  const handleClick = (state) => {
    if (isScrolled && isMobile()) {
      dispatch({ type: "CLOSE_SWITCHER" , payload: false});
    }
    if (state === 'sun') {
      dispatch({ type: "HIDE_HOMEPAGE_DARK_MODE" , payload: "sun"});
      window.localStorage.setItem("darkModePreference", "sun");
    } else {
      dispatch({ type: "SHOW_HOMEPAGE_DARK_MODE" , payload: "moon"});
      window.localStorage.setItem("darkModePreference", "moon");
    }
  }
  
  const handleDocumentClick = (event) => {
    if (switchBtnRef.current && !switchBtnRef.current.contains(event.target)) {
      if(isMobile()) {
        dispatch({ type: "CLOSE_SWITCHER" , payload: true});
      }
    }
  };

  useEffect(() => {
    if(isScrolled) {
      dispatch({ type: "CLOSE_SWITCHER" , payload: true});
    }
  }, [isScrolled]);

  
  useEffect(() => {
    const isHomepage = typeof window !== "undefined" && ((window.location.pathname === "/en/" || window.location.pathname === "/en")|| (window.location.pathname === "/de/" || window.location.pathname === "/de"));

    const handleScroll = () => {
      let val = typeof document !== "undefined" ? document.body.scrollTop : 0;
      if(val) {
        dispatch({ type: "CLOSE_SWITCHER" , payload: true });
      } else if (!isHomepage && !val) {
        dispatch({ type: "CLOSE_SWITCHER" , payload: true });
      } else {
        dispatch({ type: "CLOSE_SWITCHER" , payload: false });
      }
    };
    
    document.body.addEventListener("scroll", handleScroll);

    if(isMobile()) {
      document.body.addEventListener("click", handleDocumentClick);
    }

    if (isHomepage) {
      dispatch({ type: "CLOSE_SWITCHER" , payload: false});
    } else {
      dispatch({ type: "CLOSE_SWITCHER" , payload: true});
    }

    // Cleanup the event listener when the component unmounts
    return () => {
      document.body.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // if (state.isPageTransition.isPageTransitionActive) {
    //   setTimeout(() => {
    //     setFadeOut(true)
    //   }, 400); 
    // }

  }, [state.isPageTransition.isPageTransitionActive]); 
  return (
    <IntlProvider locale={language} messages={messages[language]}>
        {isAnchorNavPage() && anchorLinks &&  anchorLinks.length > 0 && (<AnchorNavigation anchorLinks={anchorLinks}/>)}
        {header && <SiteHeader redirectTo={redirectTo && redirectTo} header={header} withNavigation={true}  serviceEmail={serviceEmail} showWhiteIcon={showWhiteIcon} showBlackNav={showBlackNav} anchorLinks={anchorLinks} showX={showX} isImage={isImage}/>}
        <main className="main main-index">
          {children}
        </main>
      {!isContactPage && footer && (
          footer.theme && footer.theme === 'short' ? (
              <SiteFooterShort
                  footer={footer}
                  footerBgColor={servicesfooterColor || footerBgColor}
              />
          ) : (
              <SiteFooter
                  footer={footer}
                  footerBgColor={servicesfooterColor || footerBgColor}
                  isContactPage={isContactPage}
              />
          )
      )}
      <div
          style={{ backgroundColor: state.isPageTransition.color }}
          className={classNames("page-transition", {"active": state.isPageTransition.isPageTransitionActive})}
         >       
        {svgUrl && state.isPageTransition.isPageTransitionActive && (
          <div className={classNames("page-transition-inner w-100 h-100 position-relative", {"page-transition-fill": logosBgColor !== "color"})}>
            <div 
              className={classNames("position-absolute bottom-50 end-50 page-transition-svg", {"page-transition--fade-out": fadeOut})} 
              style={logosBgColor === "color" ? {} : {fill: logosBgColor }}
            >
              <SVG 
                src={svgUrl} 
                width={breakpoints.sm ? '250px' : '300px'}
                height="auto"
              />
            </div>
          </div>
        )}
        </div>
        <SwitchBtn 
          icons={[sun, moon ]}
          hasSwitcherAnimation={hasSwitcherAnimation}
          darkModePreference={darkModePreference}
          isScrolled={isScrolled}
          switchBtnRef={switchBtnRef}
          handleClick={handleClick}
        />
    </IntlProvider >
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
