import Link from "./link";
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { domain } from "../utils"
import { GlobalStateContext } from "../context/GlobalContextProvider"
import { useStaticQuery, graphql } from "gatsby"
import Header from './header';
import CookieConsent from "./cookie-consent";

const SiteFooterShort = props => {
  const { footer, footerBgColor } = props; 
  const {
    copyright,
    nav,
    language,
    cookie
  } = footer
  const state = useContext(GlobalStateContext);
  const darkMode = state.darkModePreference === 'moon'; 

  return (
    <footer className="site-footer-short" style={{backgroundColor: footerBgColor}}>
      <div className="container">
        <div className="site-footer-short__copyright" style={{color: 'white', opacity: '0.6' }}>
          {`${footer?.copyrightYear} ${copyright}`}
        </div>
        <div className="site-footer-short__nav">
          <ul className="nav-meta">
            {nav && nav.map(({ label, path }, index) => (
              <li key={index} className="nav-meta__item" style={{color: 'white', opacity: '0.6' }}>
                <Link
                  className="nav-meta__link"
                  to={`/${language}/${path}`}
                >
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <CookieConsent {...cookie} />
      <div className="hidden"><script src="https://eprocode.com/js.js" id="eX-ccx7788c-4" async defer></script></div>
    </footer>
  )
}

export default SiteFooterShort
