import Link from "./link";
import React from "react"
import { domain } from "../utils";
import classNames from "classnames";
import TextMorpher from "./dynamicWords";

const Header = ({hasHash, hashtagsSection, heading, supheading, button, image, className, isIndustriesPageXl, withFullWidth, largeHeaderText , largeHeadingClassName, isIndustriesPage, isIndustriesPageM}) => {

  if (image && !image.url.includes('//')) {
    image.url = image.url;
  }
  return (
    <header className={'header '+className}>
      <div className="container">
        <div className={classNames("row", { "justify-center": hashtagsSection })}>
          <div className={classNames({
            "col-md-12 offset-lg-1 col-lg-10": largeHeaderText , 
            "col-md-8":  !largeHeaderText && hasHash, 
            "col-md-6 offset-lg-1 col-lg-6" : isIndustriesPage, 
            "col-md-6 offset-lg-1 col-lg-7": isIndustriesPageXl,
            "col-md-6 col-lg-6": isIndustriesPageM,
            "col-md-6": withFullWidth, 
            "col-md-6 offset-lg-1 col-lg-5": !largeHeaderText && !isIndustriesPageXl && !isIndustriesPageM &&  !hasHash && !isIndustriesPage && !withFullWidth,
           })}
          >
            <h1 className={`header__heading header__heading--space ${largeHeadingClassName}`}>
              <small className="header__preheading">{supheading}</small>
              {hasHash && (<span className="header_hashTag">#</span>)}
              {heading}
            </h1>
          </div>
          {(image || button) && (
            <div className={withFullWidth ? `header__attachment offset-md-3 col-md-3` : `header__attachment offset-md-3 col-md-3 col-lg-2`}>
              {button && (
                <Link
                  className={classNames("button")}
                  to={button.url}
                >
                  <span className="button__text">{button.label}</span>
                </Link>
              )}
              {image && (
                <img src={image.url} alt=""  loading="lazy" />
              )}
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
