import React, { useContext, useEffect, useState } from "react"
import classNames from "classnames"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import { isMobile } from "../utils";
import { useRef } from "react";

const SwitchBtn = ({ icons, darkModePreference, hasSwitcherAnimation, isScrolled, switchBtnRef, handleClick  }) => {


  return (
    <div 
      className={classNames("switch-btn switch-btn-mode", {"switch-btn--active": isScrolled})}
      ref={switchBtnRef}
    >
      <div 
        className={classNames(
          "box sun", 
          {"sun--animate": hasSwitcherAnimation},
          {"active": darkModePreference === "sun"}, 
          {"hide": (isScrolled && darkModePreference  === "moon")} , 
          {"show": (isScrolled && darkModePreference  === "sun")})
        }
        onClick={() => handleClick("sun")}
      >
        <img src={icons[0]} />    
      </div>
      <div 
        className={classNames(
          {"moon--animate": hasSwitcherAnimation},
          "menu moon ", 
          {"active": darkModePreference === "moon"}, 
          {"hide": (isScrolled && darkModePreference === "sun")}, 
          {"show": (isScrolled && darkModePreference  === "moon")})
        }
        onClick={() => handleClick("moon")}
      >
        <img src={icons[1]} />    
      </div>
    </div>
)}

export default SwitchBtn;
