import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

const Collapse = ({ isOpen, children }) => {
  const [height, setHeight] = useState(0);
  const refBody = useRef();

  useEffect(() => { 
    let timeout;
    
    if (isOpen) {
      setHeight(refBody.current.scrollHeight);
      timeout = setTimeout(() => { setHeight('none')}, 150); // After animate scroll out, we make it auto-height for responsiveness
    } else {
      setHeight(refBody.current.scrollHeight);
      timeout = setTimeout(() => {setHeight(0)}, 1);
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [isOpen]);

  return (
    <div className="collapse" ref={refBody} style={{ maxHeight: height }}>
      {children}
    </div>
  )
}

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool
}

export default Collapse
