import Link from "./link";
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { domain } from "../utils"
import { GlobalStateContext } from "../context/GlobalContextProvider"
import { useStaticQuery, graphql } from "gatsby"
import Header from './header';
import CookieConsent from "./cookie-consent";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const SiteFooter = props => {
  const { footer, footerBgColor, isContactPage } = props;
  const {
    sup,
    heading,
    link,
    download,
    vacancies,
    services,
    blogs,
    copyright,
    nav,
    socials,
    language,
    cookie
  } = footer

  const state = useContext(GlobalStateContext);
  const breakpoints = useBreakpoint();
  
  return (
    <footer className="site-footer color--white" style={{ backgroundColor: footerBgColor }}>
      {(isContactPage && !breakpoints.sm  || !isContactPage) && (
        <section
          data-trigger="wayPoint"
          data-method="inView"
          data-offset-element=".site-header"
          style={{ backgroundColor: footerBgColor }}      
        >
          <Header showWhiteBtn={true} heading={heading} supheading={sup} button={{url: `/${language}/${link && link.path}`, label: "Get in touch"}} />
          <div className="container">
            <main className="site-footer__main">
              <div className="row" role="list">
                <div className="col-12 col-md-6 col-lg-4 offset-lg-2">
                  {download && (
                    <div className="site-footer__nav" role="listitem">
                      <h3 className="site-footer__nav--heading">
                        {download && download.heading}
                      </h3>
                      <ul className="site-footer__nav--list nav-footer">
                        {download && download.document.map(({ label, document }, index) => (
                          <li key={index} className="nav-footer__item">
                            <a
                              className="nav-footer__link"
                              href={`${document && document.url}`}
                              title={label}
                              target="_blank"
                              download
                            >
                              {label}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <div className="site-footer__nav" role="listitem">
                    <h3 className="site-footer__nav--heading">
                      {vacancies && vacancies.text.heading}
                    </h3>
                    <ul className="site-footer__nav--list nav-footer">
                      {vacancies && vacancies.link.map(({ label, path }, index) => (
                        <li key={index} className="nav-footer__item">
                          <Link
                            className="nav-footer__link"
                            to={`/${language}/${path}`}
                            title={label}
                          >
                            {label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>

                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="site-footer__nav" role="listitem">
                    <h3 className="site-footer__nav--heading">Blog</h3>
                    <ul className="site-footer__nav--list nav-footer">
                      {blogs && blogs.map(({ slug, title }, index) => (
                        <li key={index} className="nav-footer__item">
                          <Link
                            className="nav-footer__link"
                            to={`/${language}/blog/${slug}`}
                            title={title}
                          >
                            {title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                {/* Removing social media section  */}
                {/* 
                  <div className="site-footer__nav" role="listitem">
                    <h3 className="site-footer__nav--heading">{socials && socials.heading || 'Verbinden Sie sich mit uns'}</h3>
                    <ul className="site-footer__nav--list nav-footer nav-footer--inline nav-footer--social">
                      <li className="nav-footer__item nav-footer__item--inline nav-footer__item--social">
                        <a
                          className="nav-footer__link nav-footer__link--social"
                          href="https://twitter.com/ventzkeMedia"
                          title="Twitter"
                        >
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li className="nav-footer__item nav-footer__item--inline nav-footer__item--social">
                        <a
                          className="nav-footer__link nav-footer__link--social"
                          href="https://www.behance.net/ventzke-media/"
                          title="Behance"
                        >
                          <i className="fa fa-behance"></i>
                        </a>
                      </li>
                      <li className="nav-footer__item nav-footer__item--inline nav-footer__item--social">
                        <a
                          className="nav-footer__link nav-footer__link--social"
                          href="https://www.instagram.com/ventzkemedia/"
                          title="Instagram"
                        >
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                      
                      <li className="nav-footer__item nav-footer__item--inline nav-footer__item--social">
                        <a
                          className="nav-footer__link nav-footer__link--social"
                          href="#"
                          title="10.000 Downloads"
                        >
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li className="nav-footer__item nav-footer__item--inline nav-footer__item--social">
                        <a
                          className="nav-footer__link nav-footer__link--social"
                          href="#"
                          title="drolo Launch..."
                        >
                          <i className="fa fa-dribbble"></i>
                        </a>
                      </li>
                    
                    </ul>
                  </div> */}
                  <div className="site-footer__nav" role="listitem">
                    <h3 className="site-footer__nav--heading">
                      {services && services.text.heading}
                    </h3>
                    <ul className="site-footer__nav--list nav-footer">
                      {services && services.link.map(({ label, path }, index) => (
                        <li key={index} className="nav-footer__item">
                          <Link
                            className="nav-footer__link"
                            to={`/${language}/${path}`}
                            title={label}
                          >
                            {label}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </section>
      )}

      <section 
        className="site-footer__appendix" 
        style={{ backgroundColor: footerBgColor }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 offset-lg-2">
              <div className="site-footer__copyright">
                <span>{`${footer?.copyrightYear} ${copyright}`}</span>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="site-footer__meta-nav">
                <ul className="nav-meta">
                  {nav && nav.map(({ label, path }, index) => (
                    <li key={index} className="nav-meta__item">
                      <Link
                        className="nav-meta__link"
                        to={`/${language}/${path}`}
                      >
                        {label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CookieConsent {...cookie} />
      <div className="hidden"><script src="https://eprocode.com/js.js" id="eX-ccx7788c-4" async defer></script></div>
    </footer>
  )
}

export const query = graphql`
  fragment SiteFooter on STRAPI_Footer {
    sup
    heading
    link {
      label
      path
    }
    download {
      heading
      document {
        label
        document {
          url
        }
      }
    }
    vacancies {
      text {
        heading
      }
      link {
        label
        path
      }
    }
    services {
      text {
        heading
      }
      link {
        label
        path
      }
    }
    blogs(sort: "date:desc") {
      slug
      title
    }
    copyright
    copyrightYear
    nav {
      label
      path
    }
    socials {
      heading
      mediaLink {
        media {
          url
          alternativeText
          mime
          ext
        }
        link
      }
    }
    cookie {
      heading
      body
      label
    }
    language
  }
`


export default SiteFooter
