

import classNames from "classnames";
import React from "react"
import { useState } from "react";
import iconCloseWhite from "../images/close-x.svg";
import iconCloseBlack from "../images/close-black.svg";
import { useEffect } from "react";
import Link from "./link";
import { debounce, isMobile, scrollToSection, stripString, truncateText } from "../utils";
import Scrollspy from "react-scrollspy";

let anchoNav = false; 

function  AnchorNavigation ({anchorLinks}) { 
  const [anchorText, setAnchorText] = useState("Abschnitt wählen"); 
  const [isAnchorMenu, showAnchorMenu] = useState(false); 
  const [showXBtn, setShowXbtn] = useState(false); 
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(undefined); 
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const  navHighlighter = () => {
    
    if (scrollPos <= 1) {
      setScrollPos(
        typeof document !== "undefined" ? document.body.scrollTop : 0
      );
    }
  }

    useEffect(() => {
      // Add an event listener listening for scroll
      document.body.addEventListener("scroll", function () {
        debounce(() => navHighlighter(), 600);
      });
      return () => {
        document.body.removeEventListener("scroll", navHighlighter);
      };
    }, []); 
   
    useEffect(() => {
        
      const sections = Array.from(document.querySelectorAll("section[id]:not([id='']), div.anchor-text"));
      const options = {
        threshold: isMobile() ? 0 :  0.1, // Adjust this value as needed
      };

      const jadeScaleObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const { intersectionRatio, target } = entry;
          if (entry.isIntersecting) {
            setIsIntersecting(true);
            const index = Array.from(sections).indexOf(entry.target);
            index !== undefined && anchorLinks[index]?.header  && setSelectedMenuIndex(index);
            index !== undefined && anchorLinks[index]?.header && setAnchorText(anchorLinks[index].header); 
          } else {

          }
        });
      }, options);

      sections.forEach((section) => {
        jadeScaleObserver.observe(section);
      });

      return () => {
        jadeScaleObserver.disconnect();
      };
    }, [anchorLinks]);
  
  const hasActiveMenuItems = anchorLinks && anchorLinks.filter((item) => item?.header ); 

  if (hasActiveMenuItems.length === 0 ) {
    return null
  }; 
  const mobileText = anchorText ? truncateText(anchorText, 20) : "Abschnitt wählen"; 

  return (
    <>
      {anchorLinks.length >  0 && (
        <>
          <div className={`anchor-nav-overlay ${showXBtn ? 'show' : ''}`}></div>
          <>
              <div className={classNames( `anchor-nav-menu ${isAnchorMenu ? 'active' : ''}` , {show: showXBtn})}>
                    {/* <Scrollspy
                      className="anchor-nav-menu-list"
                      items={anchorLinksMenu}
                      currentClassName="is-current"
                    > */}

                      {anchorLinks && anchorLinks.filter((link) => link.display).map((item, index) => {
                        const activeMenu = index === selectedMenuIndex; 
                          const activeClas = "is-current"; 
                          return (
                          <li className={classNames("anchor-nav-menu-item", {
                            [activeClas]: activeMenu
                          })}
                            key={`anchor-nav-menu-item-${index}`}
                          >
                            <span
                              onClick={() => {
                                setTimeout(() => {
                                //  setSelectedMenuIndex(index);
                                //  setAnchorText(item.header); 
                                 scrollToSection(`${item.header ?  stripString(item.header) : ''}`)
                                 showAnchorMenu(false)
                                 setShowXbtn(false)
                                }, 0)
                                // document.getElementById(stripString(item.header)) && document.getElementById(stripString(item.header)).scrollIntoView();
                              }}
                              href={`#${item.header ?  stripString(item.header) : ''}`}
                              className={ classNames("anchor-nav-menu-link ", {
                              "active": activeMenu
                              })} 
                              
                            >
                              {item.header} 
                            </span>
                          </li>
                          )
                      })}
                    {/* </Scrollspy> */}
              </div>
              <button 
                className={classNames("anchor-nav-btn" , {active: !isAnchorMenu && scrollPos > 0  })}
                onClick={() => {
                  showAnchorMenu(true); 
                  setTimeout(()=> {
                    setShowXbtn(true)
                  }, 200)
                  anchoNav = true; 
                }}
              >
                <span >
                  {isMobile() ? mobileText : (anchorText ? anchorText : "Abschnitt wählen")}
                </span>
                {!isAnchorMenu && selectedMenuIndex !== undefined && anchorText !== undefined && (
                  <img 
                    className="anchor-nav-menu-close-img" 
                    src={iconCloseWhite} loading="lazy" 
                    onClick={(event) => {
                      event.stopPropagation(); 
                      document.body.scrollTo(0,0)
                      setSelectedMenuIndex(undefined); 
                      setAnchorText(undefined); 
                      window.location.hash = ""; 
                    }}
                  />
                )}
              </button>  
              <div className={classNames("anchor-nav-menu-close", {show: showXBtn} )} onClick={() => {
                showAnchorMenu(false)
                setShowXbtn(false)
              }}> 
                <img className="anchor-nav-menu-close-img" src={iconCloseBlack} loading="lazy" />
              </div>
          </>
        </>
      )}
    </>

  );
}

export default AnchorNavigation; 


