import React from "react"
import { Link as GatsbyLink } from "gatsby"
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to , href, activeClassName, partiallyActive, ...other }) => {
  if (href) { to = href; }

  if (!to) {
    return null
  }
  const isMailto = to.startsWith('mailto:');
  if (isMailto) {
    return (
      <a href={to} {...other}>
        {children}
      </a>
    )
  }
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = to && !to.includes('//');
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    const isFile = to.split('/').pop().includes('.');
    const hasTrailingSlash = to.endsWith('/');
    if (!isFile && !hasTrailingSlash) { to = to + '/'; }

    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} target="_blank" {...other}>
      {children}
    </a>
  )
}
export default Link