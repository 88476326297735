import React from "react";

export const CustomHr = ({ borderBottom }) => {
  return <hr  style={{ borderBottom: borderBottom, opacity: '0.6' }} />;
}

export const Code = ({ children, border }) => {
  return (
    <code  style={{ border: border }}>
     {children}
    </code>
  );
}

export const Div = ({ children, border }) => {
  return (
    <div  style={{ border: border }}>
     {children}
    </div>
  );
}







